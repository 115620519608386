import { FC } from 'react'
import { ModalCatEdit } from '@/components/Modal'
import { useModal } from '@/libs/HOC/ModalHOC/ModalHOC'
import { MoreEditSvg, PlusSvg } from '@/_assets/svg/editTag'
import { TCategoriesRes } from '@/services/API/categories/categories.type'
import s from './CatVariantEdit.module.scss'
import { THandleEditParams } from '../../CatVariantResource/CatVariantResource.type'
import WrapperLang from '@/components/WrapperLang/WrapperLang'
import { routes } from '@/constants/routes'
import { Link } from 'react-router-dom'
import { useLang } from '@/libs/context/LocalLangContext/LocalLangContext'
type TProps = {
	category?: TCategoriesRes | null
	handleEdit: (params: THandleEditParams) => void
}
const CatVariantEdit: FC<TProps> = ({ category, handleEdit }) => {
	const { openModal } = useModal()
	return (
		<div className={s.container}>
			<Link to={routes.ADD_CATEGORY}
				// onClick={() => {
				// 	openModal({
				// 		component: (
				// 			<WrapperLang>
				// 				<ModalCatEdit
				// 					variantEdit="create"
				// 					parent_id={category?.id}
				// 					actions={{
				// 						callbackSuccess: (params) =>
				// 							handleEdit({
				// 								data: params,
				// 								variantEdit: 'create',
				// 							}),
				// 					}}
				// 				/>
				// 			</WrapperLang>
				// 		),
				// 		settingModal: {},
				// 	})
				// }}
			>
				<PlusSvg />
			</Link>
			{category && (
				<Link to={`${routes.UPDATE_CATEGORY}/${category.id}`}
					// onClick={() => {
					// 	openModal({
					// 		component: (
					// 			<WrapperLang>
					// 				<ModalCatEdit
					// 					variantEdit="update"
					// 					category={category}
					// 					actions={{
					// 						callbackRemove: (params) => {
					// 							handleEdit({
					// 								data: params,
					// 								variantEdit: 'remove',
					// 							})
					// 						},
					// 						callbackSuccess: (params) =>
					// 							handleEdit({
					// 								data: params,
					// 								variantEdit: 'update',
					// 							}),
					// 					}}
					// 				/>
					// 			</WrapperLang>
					// 		),
					// 		settingModal: {
					// 			isOuterClose: true,
					// 		},
					// 	})
					// }}
				>
					<MoreEditSvg />
				</Link>
			)}
		</div>
	)
}
export default CatVariantEdit
