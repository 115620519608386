export const countries = {
	ru: [
		'Албания',
		'Андорра',
		'Армения',
		'Австрия',
		'Азербайджан',
		'Беларусь',
		'Бельгия',
		'Босния и Герцеговина',
		'Болгария',
		'Хорватия',
		'Кипр',
		'Чехия',
		'Дания',
		'Эстония',
		'Финляндия',
		'Франция',
		'Грузия',
		'Германия',
		'Греция',
		'Венгрия',
		'Исландия',
		'Ирландия',
		'Италия',
		'Казахстан',
		'Косово',
		'Латвия',
		'Лихтенштейн',
		'Литва',
		'Люксембург',
		'Мальта',
		'Молдова',
		'Монако',
		'Черногория',
		'Нидерланды',
		'Северная Македония',
		'Норвегия',
		'Польша',
		'Португалия',
		'Румыния',
		'Россия',
		'Сан-Марино',
		'Сербия',
		'Словакия',
		'Словения',
		'Испания',
		'Швеция',
		'Швейцария',
		'Турция',
		'Украина',
		'Великобритания',
		'Афганистан',
		'Алжир',
		'Ангола',
		'Аргентина',
		'Австралия',
		'Багамы',
		'Бахрейн',
		'Бангладеш',
		'Барбадос',
		'Белиз',
		'Бенин',
		'Бутан',
		'Боливия',
		'Ботсвана',
		'Бразилия',
		'Бруней',
		'Бурунди',
		'Камбоджа',
		'Камерун',
		'Канада',
		'Кабо-Верде',
		'Чили',
		'Китай',
		'Колумбия',
		'Коста-Рика',
		'Куба',
		'Доминиканская Республика',
		'Эквадор',
		'Египет',
		'Сальвадор',
		'Эфиопия',
		'Фиджи',
		'Габон',
		'Гамбия',
		'Гана',
		'Гватемала',
		'Гондурас',
		'Индия',
		'Индонезия',
		'Израиль',
		'Ямайка',
		'Япония',
		'Иордания',
		'Кения',
		'Кувейт',
		'Ливан',
		'Либерия',
		'Мадагаскар',
		'Малави',
		'Малайзия',
	],
	en: [
		'Albania',
		'Andorra',
		'Armenia',
		'Austria',
		'Azerbaijan',
		'Belarus',
		'Belgium',
		'Bosnia and Herzegovina',
		'Bulgaria',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Georgia',
		'Germany',
		'Greece',
		'Hungary',
		'Iceland',
		'Ireland',
		'Italy',
		'Kazakhstan',
		'Kosovo',
		'Latvia',
		'Liechtenstein',
		'Lithuania',
		'Luxembourg',
		'Malta',
		'Moldova',
		'Monaco',
		'Montenegro',
		'Netherlands',
		'North Macedonia',
		'Norway',
		'Poland',
		'Portugal',
		'Romania',
		'Russia',
		'San Marino',
		'Serbia',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
		'Switzerland',
		'Turkey',
		'Ukraine',
		'United Kingdom',

		'Afghanistan',
		'Algeria',
		'Angola',
		'Argentina',
		'Australia',
		'Bahamas',
		'Bahrain',
		'Bangladesh',
		'Barbados',
		'Belize',
		'Benin',
		'Bhutan',
		'Bolivia',
		'Botswana',
		'Brazil',
		'Brunei',
		'Burundi',
		'Cambodia',
		'Cameroon',
		'Canada',
		'Cape Verde',
		'Chile',
		'China',
		'Colombia',
		'Costa Rica',
		'Cuba',
		'Dominican Republic',
		'Ecuador',
		'Egypt',
		'El Salvador',
		'Ethiopia',
		'Fiji',
		'Gabon',
		'Gambia',
		'Ghana',
		'Guatemala',
		'Honduras',
		'India',
		'Indonesia',
		'Israel',
		'Jamaica',
		'Japan',
		'Jordan',
		'Kenya',
		'Kuwait',
		'Lebanon',
		'Liberia',
		'Madagascar',
		'Malawi',
		'Malaysia',
	],
}
