import React from 'react'

function RaiffeisenSvg() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			fill="none"
			viewBox="0 0 18 18"
		>
			<path
				fill="#FFE600"
				d="M18 14.598C18 16.785 16.785 18 14.598 18H3.402C1.215 18 0 16.785 0 14.598V3.402C0 1.215 1.215 0 3.402 0h11.2C16.785 0 18 1.215 18 3.402v11.196z"
			></path>
			<path
				fill="#2B2D34"
				d="M11.574 8.122V6.403l.62-.62v1.489l.54.54 2.174-2.174.536.536c-.005-.405-.086-1.58-1.08-2.556-1.071-1.035-2.232-.567-2.628-.171l-2.17 2.169.554.553L9 7.3l-1.12-1.12.553-.554-2.17-2.17c-.395-.395-1.556-.863-2.627.172-.995.976-1.076 2.15-1.08 2.556l.535-.536 2.174 2.174.54-.54V5.79l.62.621v1.72L7.3 9l-4.302 4.302 1.7 1.696L9 10.701l4.302 4.302 1.7-1.697L10.702 9l.873-.878z"
			></path>
		</svg>
	)
}

export default RaiffeisenSvg
