import {
	ArticlesSvg,
	HistorySvg,
	NewsSvg,
	RobotsSvg,
	TagsSvg,
} from '@/_assets/svg/nav'

export const INDEX_PAGE = '/'
const ADMIN_PAGE = 'admin'

const CASINO = 'casino'
const ADD_CASINO = 'add-casino'
const UPDATE_CASINO = 'update-casino'
const NEWS = 'news'
const ADD_NEWS = 'add-news'
const UPDATE_NEWS = 'update-news'
const ARTICLE = 'article'
const ADD_ARTICLE = 'add-article'
const UPDATE_ARTICLE = 'update-article'
const HISTORY = 'history'
const ADD_HISTORY = 'add-history'
const UPDATE_HISTORY = 'update-history'
const ACCOUNT = 'account'
const ADD_CATEGORY = 'add-category'
const UPDATE_CATEGORY = 'update-category'
export const routes = {
	ADMIN_PAGE,
	CASINO,
	ADD_CASINO,
	UPDATE_CASINO,
	NEWS,
	ADD_NEWS,
	UPDATE_NEWS,
	ARTICLE,
	ADD_ARTICLE,
	UPDATE_ARTICLE,
	HISTORY,
	ADD_HISTORY,
	UPDATE_HISTORY,
	ACCOUNT,
	SETTING_ROBOTS: 'setting-robots',
	CATEGORIES: 'categories',
	ADD_CATEGORY,
	UPDATE_CATEGORY
}
export const navigate = [
	{
		name: 'Казино',
		link: CASINO,
		icon: ArticlesSvg,
	},
	{
		name: 'Новости',
		link: NEWS,
		icon: NewsSvg,
	},
	{
		name: 'Статьи',
		link: ARTICLE,
		icon: ArticlesSvg,
	},
	{
		name: 'Истории',
		link: HISTORY,
		icon: HistorySvg,
	},
	{
		name: 'Robots.txt',
		link: routes.SETTING_ROBOTS,
		icon: RobotsSvg,
	},
	{
		name: 'Категории',
		link: routes.CATEGORIES,
		icon: TagsSvg,
	},
]
