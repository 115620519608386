import { LangProvider } from '@/libs/context/LocalLangContext/LocalLangContext'
import React, { FC, ReactNode } from 'react'

type TProps = {
    children: ReactNode
}

const WrapperLang: FC<TProps> = ({children}) => {
  return (
    <LangProvider>
        {children}
    </LangProvider>
  )
}

export default WrapperLang