import { useLocation, useParams } from 'react-router-dom'
import WrapperAddNewArticle from '@/features/Constructor/Article/WrapperAddNewArticle'
import { TLocationState } from './type'

export const UpdateArticle = () => {
	const { state }: TLocationState = useLocation()
	const { itemId } = useParams()
	return (
		<WrapperAddNewArticle
			editFor="UPDATE"
			labelPage="Обновление статьи"
			id={state?.itemId || Number(itemId)}
		/>
	)
}

export default UpdateArticle
