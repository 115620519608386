import React from 'react'

function LabelSvg() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="210"
			height="30"
			fill="none"
			viewBox="0 0 210 30"
		>
			<path
				fill="#2ABE42"
				d="M191.199 24.369V5.632h4.053v10.5l8.842-10.5h5.263l-7.868 9.342 7.868 9.395h-5.289l-5.264-6.211-3.552 4.132v2.079h-4.053zM185.366 17.737l3.579 1.921c-1.658 3.184-4.974 5.342-8.869 5.342-5.5 0-10-4.474-10-10.026 0-5.474 4.5-9.974 10-9.974a9.983 9.983 0 018.869 5.368l-3.579 1.869c-1-1.895-2.974-3.21-5.29-3.21-3.263 0-5.947 2.71-5.947 5.947 0 3.316 2.684 6 5.947 6 2.316 0 4.29-1.316 5.29-3.237zM149.837 24.369l8.237-18.737h3.789l8.263 18.737h-4.394l-.974-2.158h-9.605l-.921 2.158h-4.395zm6.947-6.027h6.369l-3.185-7.658-3.184 7.658zM136.299 16.474h4.5c2.21 0 3.894-1.605 3.894-3.448 0-1.842-1.789-3.447-4-3.447h-4.394v6.895zm-4.053 7.895V5.632h8.605c4.237 0 7.895 3.21 7.895 7.394 0 2.606-1.447 4.816-3.526 6.132l3.631 5.21h-4.947l-2.763-3.973c-.026.026-.237.026-.263.026h-4.579v3.948h-4.053zM119.269 24.369V9.632h-6.552v-4h17.079v4h-6.5v14.737h-4.027z"
			></path>
			<path
				fill="#fff"
				d="M108.25 22.026c0-1.631 1.29-2.947 2.974-2.947a2.943 2.943 0 012.948 2.947c0 1.685-1.316 2.974-2.948 2.974-1.684 0-2.974-1.29-2.974-2.974zM86.888 14.974c0-5.474 4.5-9.974 10-9.974 5.527 0 10.027 4.5 10.027 9.974 0 5.552-4.5 10.026-10.027 10.026-5.5 0-10-4.474-10-10.026zm15.974 0c0-3.237-2.658-5.948-5.974-5.948-3.263 0-5.947 2.711-5.947 5.948 0 3.316 2.684 6 5.947 6 3.316 0 5.974-2.684 5.974-6zM66.97 24.369V5.632h4.342l9.079 12.526V5.632h4.053v18.737h-4.316l-9.105-12.5v12.5H66.97zM59.44 24.369V5.632h4.053v18.737H59.44zM40.013 21.658l2.447-3.395c2.79 1.974 5.027 2.684 6.842 2.684 2.132 0 3.448-.815 3.448-1.631 0-3.132-12.421-1.947-12.421-8.5C40.329 7.553 43.408 5 47.724 5c2.42 0 5.263.816 8.42 2.842l-2.263 3.421c-2.526-1.605-4.71-2.237-6.394-2.237-1.921 0-3.106.816-3.106 1.632 0 2.395 12.448 1.605 12.448 8.579 0 3.079-3.08 5.763-7.684 5.763-2.658 0-5.764-1-9.132-3.342zM19.157 24.369l8.237-18.737h3.79l8.262 18.737h-4.394l-.974-2.158h-9.605l-.921 2.158h-4.395zm6.947-6.027h6.369l-3.184-7.658-3.185 7.658zM15.29 17.737l3.578 1.92C17.211 22.843 13.895 25 10 25 4.5 25 0 20.526 0 14.974 0 9.5 4.5 5 10 5a9.983 9.983 0 018.868 5.368l-3.578 1.869c-1-1.895-2.974-3.21-5.29-3.21-3.263 0-5.947 2.71-5.947 5.947 0 3.316 2.684 6 5.947 6 2.316 0 4.29-1.316 5.29-3.237z"
			></path>
		</svg>
	)
}

export default LabelSvg
