import React from 'react'
import { Outlet } from 'react-router-dom'

const News = () => {
	return (
		<>
			<Outlet />
		</>
	)
}

export default News
