import { useLocation, useParams } from 'react-router-dom'
import WrapperAddNewNews from '@/features/Constructor/News/WrapperAddNewNews'
import { TLocationState } from './type'

export const UpdateNews = () => {
	const { state }: TLocationState = useLocation()
	const { itemId } = useParams()

	return (
		<WrapperAddNewNews
			editFor="UPDATE"
			labelPage="Обновление новости"
			id={state?.itemId || Number(itemId)}
		/>
	)
}

export default UpdateNews
