import { FC, useEffect, useState } from 'react'
import { Input } from '@/libs/UI'
import { Button, P } from '@/libs/UI/CustomTags'
import { CloseWithBgcSvg } from '@/_assets/svg/close'
import { useModal } from '@/libs/HOC/ModalHOC/ModalHOC'
import { generateLink } from '@/libs/utils/generateLink'
import { TCategoriesRes } from '@/services/API/categories/categories.type'
import s from './ModalCatEdit.module.scss'
import { LanguageProvider, TLangKey, useLanguage } from '../../../libs/context/LanguageProvider'
import {
	THandleChangeFromParams,
	TModalCatEditProps,
	TStateModalCat,
} from './ModalCatEdit.type'
import ContentHTML from '../../../libs/UI/ContentHTML/ContentHTML'
import { LangProvider, useLang } from '@/libs/context/LocalLangContext/LocalLangContext'
import LangSelector from '@/components/LangSelector/LangSelector'

export const INIT_DATA: TCategoriesRes = {
	link: '',
	id: 0,
	parent_id: null,
	title: '',
	children: [],
	translations: {
		en: {
			cat_name: '',
			title_h1: '',
			meta_title: '',
			meta_description: '',
			content: []
		},
		ru: {
			cat_name: '',
			title_h1: '',
			meta_title: '',
			meta_description: '',
			content: []
		},
	},
}

const ModalCatEdit: FC<TModalCatEditProps> = ({
	actions,
	category = null,
	variantEdit,
	parent_id = null,
}) => {
	const [formData, setFormData] = useState<TStateModalCat>(INIT_DATA)
	const { closeModal } = useModal()
	const { getLocalization } = useLanguage()
	const { lang, handleChangeLang } = useLang()

	///
	const handleChangeForm = ({ key, value, lang }: THandleChangeFromParams) => {
		setFormData((prev) => {
			if (lang) {
				return {
					...prev,
					translations: {
						...prev.translations,
						[lang]: {
							...prev.translations[lang],
							[key]: value,
						},
					},
				}
			}
			return {
				...prev,

				link: key === 'title' ? generateLink(value) : prev.link,
				[key]: value,
			}
		})
	}

	///

	useEffect(() => {
		if (category) {
			const transformData: TCategoriesRes = {
				...category,
				translations: category.translations,
			}
			setFormData(transformData)
		}
	}, [category])
	

	return (
		<div className={s.container}>
			<div className={s.close} onClick={() => closeModal()}>
				<CloseWithBgcSvg />
			</div>
			<div className={s.header}>
				<P size="xl">{category ? formData.title : 'Новый тег'}</P>
			</div>
			<div className={s.wrapForm}>
				<div className={s.blockInputs}>
					<Input
						placeholder="Title"
						placeholder_type="is_shown"
						value={formData.title}
						onChange={(e) =>
							handleChangeForm({
								key: 'title',
								value: e.target.value,
							})
						}
					/>
					<Input
						placeholder="Link"
						value={formData.link}
						placeholder_type="is_shown"
						onChange={(e) =>
							handleChangeForm({
								key: 'link',
								value: e.target.value,
							})
						}
					/>

					<div className={s.blockByLang}>
						<div className={s.boxByLang}>
							<div className={s.lang}>
								<LangSelector activeLang={lang} callback={handleChangeLang} />
							</div>
							<Input
								placeholder={`Cat name ${lang.toUpperCase()}`}
								placeholder_type="is_shown"
								value={formData.translations[lang].cat_name}
								onChange={(e) =>
									handleChangeForm({
										key: 'cat_name',
										value: e.target.value,
										lang
									})
								}
							/>
							<Input
								placeholder={`H1 ${lang.toUpperCase()}`}
								placeholder_type="is_shown"
								value={formData.translations[lang].title_h1}
								onChange={(e) =>
									handleChangeForm({
										key: 'title_h1',
										value: e.target.value,
										lang
									})
								}
							/>
							<Input
								placeholder={`Meta Title ${lang.toUpperCase()}`}
								placeholder_type="is_shown"
								value={formData.translations[lang].meta_title}
								onChange={(e) =>
									handleChangeForm({
										key: 'meta_title',
										value: e.target.value,
										lang
									})
								}
							/>
							<Input
								placeholder={`Meta description ${lang.toUpperCase()}`}
								placeholder_type="is_shown"
								value={formData.translations[lang].meta_description}
								onChange={(e) =>
									handleChangeForm({
										key: 'meta_description',
										value: e.target.value,
										lang
									})
								}
							/>
						</div>
					</div>
				</div>

				<div className={s.actions}>
					{variantEdit === 'update' && (
						<Button
							type="empty"
							onClick={() => {
								actions?.callbackRemove &&
									category?.id &&
									actions?.callbackRemove({
										...formData,
									})
								closeModal()
							}}
						>
							{getLocalization('Удалить')}
						</Button>
					)}

					<Button
						type="primary"
						onClick={() => {
							if (variantEdit === 'create') {
								actions?.callbackSuccess({
									...formData,
									parent_id: parent_id,
								})
							}
							if (variantEdit === 'update') {
								actions?.callbackSuccess({
									...formData,
								})
							}
							closeModal()
						}}
					>
						{getLocalization('Сохранить')}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default ModalCatEdit
